<template>
  <div class="logging-details-container wrapper">
    <CRow class>
      <CCol lg="9" md="9">
        <CCard>
          <CCardHeader>
            <router-link :to="loggingListLink">
              <CIcon name="cil-arrow-left" class="mr-1" />
              {{ $t('LOGGING_DETAILS_COMPONENT_BACK_TO_LOGGING_LIST') }}
            </router-link>
          </CCardHeader>
          <CCardBody v-if="isLoading">
            <vcl-list></vcl-list>
          </CCardBody>
          <CCardBody>
            <CRow>
              <CCol lg="2" md="2">
                <label>{{ $t('LOGGING_DETAILS_COMPONENT_LABEL_DATE') }}</label>
              </CCol>
              <CCol lg="6" md="6">
                <span>{{ loggingDetails.created_at | moment(dateFormat) }}</span>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="2" md="2">
                <label>{{ $t('LOGGING_DETAILS_COMPONENT_LABEL_TYPE') }}</label>
              </CCol>
              <CCol lg="6" md="6">
                <span>{{loggingDetails.type}}</span>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="2" md="2">
                <label>{{ $t('LOGGING_DETAILS_COMPONENT_LABEL_WALLET_ADDRESS') }}</label>
              </CCol>
              <CCol lg="10" md="10">
                <span>{{ loggingDetails.wallet_address }}</span>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="12" md="12">
                  <CTextarea
                    :label="$t('LOGGING_DETAILS_COMPONENT_CONTENT_FIELD_MESSAGE')"
                    rows="12"
                    :horizontal="{ label: 'col-sm-12', input: 'col-sm-12'}"
                    v-model="loggingDetails.message"
                  />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>

import { VclList } from 'vue-content-loading';
import { endpoints, env, Constants } from '@/constants';
import responseHelper from '@/utils/response-helper';
import StakingBadge from '@/components/StakingBadge';

export default {
  name: 'LoggingDetail',
  components: {
    VclList,
    StakingBadge,
  },
  data() {
    return {
      loggingDetails: {
        type: '',
        message: '',
        wallet_address: '',
        created_at: '',
      },
      loggingListLink: '/admin/setting/logging',
      isLoading: true,
      dateFormat: Constants.dateFormat,
    };
  },
  async mounted() {
    const id = this.$route.params.id;
    await Promise.all([this.getLoggingDetails(id)]);
    this.isLoading = false;
  },
  methods: {
    async getLoggingDetails(id) {
      try {
        const res = await this.$http.get(endpoints.getLoggingDetails(id));
        this.loggingDetails = res.data;
        this.isLoading = false;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: responseHelper.getErrorMessage(err),
        });
      }
    },
  },
};
</script>
<style lang="scss">
.logging-details-container {
  &.wrapper {
    padding: 0 24px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #657187;
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 0px;
      }
    }
  }
}
</style>
